import React, { useEffect, useState } from 'react'
import LeftNav from '../IndianRailways/LeftNav';
import './batches.css'
import './batchDetails.css'
import EnhancedTable from './TableMUI';
import BatchDetails from './BatchDetails';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { getCoachAnalytics } from '../application/redux_actions';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';

const Card = ({ smallText, largeText }) => {
    return (
        <div className='bold-center-text' style={{ backgroundColor: "white", borderRadius: 8, minWidth: '15vw', padding: '1rem' }}>
            <div className='smaall-text' style={{ textAlign: "left", fontWeight: 700, color: 'black' }}>{smallText}</div>
            <div className='large-text' style={{ textAlign: "left", fontWeight: 600 }}>{largeText}</div>
        </div>
    )
}

const Batches = () => {
    const [batchList, setBatchList] = useState(null);
    const [snapshot, setSnapshot] = useState({});
    const [selected, setSelected] = useState(undefined);

    let user_token = useSelector(state => state.auth?.user_token)
    let app = useSelector(state => state.app)
    let programName = app?.availableProgramsNew?.[app?.user?.programId]?.programName;
    let userId = '6431a348bc7de4001d93bf86' || app?.user?._id;
    // let userId = user?._id;
    useEffect(async () => {
        if (user_token && userId) {
            await getCoachAnalytics({ user_token, userId }).then(res => {
                if (res && res.data && res.snapshot) {
                    setBatchList(res.data);
                    setSnapshot(res.snapshot);
                }
                else { setBatchList([]) }
            })
        }
    }, [user_token, userId])



    const handleBatchClick = (batch) => {
        setSelected(batch);
    }

    if (batchList === null) {
        return <div className={"dashboard-cntr bg-gray"}>
            <LeftNav />
            <div style={{ width: '100%', height: "100%", textAlign: "center", alignContent: 'center' }}>Fetching Data For You...</div>
        </div>
    }


    const BackButton = () => <Button style={{ width: "max-content", }} onClick={() => setSelected(undefined)}> <ArrowBackOutlinedIcon /> Back</Button>
    return (
        <div className={"dashboard-cntr bg-gray"}>
            <LeftNav />

            {batchList?.length !== 0 ?
                <div className="content" style={{ display: 'flex', flexDirection: 'column', gap: selected ? '1rem' : '2rem' }}>
                    {selected ? '' : <div className="your-batches" style={{ margin: "2rem 0rem 0rem", fontSize: '1.5em' }}>
                        Your Batch Analytics
                        <div className="" style={{ fontSize: "1rem", fontWeight: 500 }}>{`${programName}`}</div>
                    </div>}

                    {selected ? <>
                        <div>
                            <BatchDetails BackButton={BackButton} setSelected={setSelected} userId={userId} selected={selected} batchList={batchList} />
                        </div>
                    </> :
                        <>
                            {batchList && <div className='flex' style={{ gap: "2.5rem" }}>
                                {Object.keys(snapshot || {}).map((item, index) => {
                                    return <Card key={item} smallText={item} largeText={Object.values(snapshot)[index]} />
                                })}
                            </div>
                            }

                            {batchList && batchList?.length !== 0 ?
                                <EnhancedTable tableTitle={'Your Batch(es)'} defaultRows={10} list={batchList} handleBatchClick={handleBatchClick} /> : null}
                        </>}
                </div> :
                <div style={{ width: '100%', height: "100%", textAlign: "center", alignContent: 'center' }}>Data will be available once you start conducting batches to the field participants...</div>
            }

        </div>
    )
}

export default Batches;


