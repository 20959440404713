import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getEngagement, getOTP } from "../containers/application/redux_actions";

import ThinkSolution_Engineer_Start_N_Stop from "./ThinkSolutions/Engineer_Start_N_Stop";
import ThinkSolution_Engineer_Bar_Chart from "./ThinkSolutions/Engineer_Bar_Chart";
import Case_Response from "./ThinkSolutions/Case_Response";
import Poll_Mcq from "./ThinkSolutions/Poll_Mcq";
import Poll_Dipstick from "./ThinkSolutions/Poll_Dipstick";
import Dynamic_Custom from "./ThinkSolutions/Dynamic_Custom";
import Slider from "./ThinkSolutions/Slider";
import Real_World_Insights from "./ThinkSolutions/Real_World_Insights";
import ThinkSolution_Engineer_Trust_O_Meter from "./ThinkSolutions/Engineer_Trust_O_Meter";
import ThinkSolution_Role_Start_N_Stop from "./ThinkSolutions/Role_Start_N_Stop";
import ThinkSolution_Entrepreneur_Start_N_Stop from "./ThinkSolutions/Entrepreneur_Start_N_Stop";
import ThinkSolution_Engineer_Pie_Chart from "./ThinkSolutions/Engineer_Pie_Chart";
import ThinkSolution_Idea_Board from "./ThinkSolutions/IdeaBord";
import ThinkSolution_Slider_Response from "./ThinkSolutions/Slider_Response";
import ThinkSolution_Idea_Board_Tree from "./ThinkSolutions/IdeaBord_Tree";
import ThinkSolution_Idea_Board_poll1 from "./ThinkSolutions/IdeaBord_poll1";
import ThinkSolution_Idea_Board_poll2 from "./ThinkSolutions/IdeaBord_poll2";
import ThinkSolution_Idea_Board_poll3 from "./ThinkSolutions/IdeaBord_poll3";
import ThinkSolution_Idea_Board_poll4 from "./ThinkSolutions/IdeaBord_poll4";
import ThinkSolution_SP_Engineer_Start_N_Stop from "./ThinkSolutions_SP/Engineer_Start_N_Stop";
import ThinkSolution_SP_Engineer_Bar_Chart from "./ThinkSolutions_SP/Engineer_Bar_Chart";
import ThinkSolution_SP_Idea_Board from "./ThinkSolutions_SP/IdeaBord";
import ThinkSolution_Idea_Bord_prir from "./ThinkSolutions/Idea_Bord_prir";
import ThinkSolution_SP_Idea_Board_Step_1_Texbox from "./ThinkSolutions_SP/Step_1_Texbox";
import ThinkSolution_SP_Idea_Board_Step_3_Texbox from "./ThinkSolutions_SP/Step_3_Texbox";
import ThinkSolution_Team_Board_1 from "./Team_Board/Team_Board_1";
import ThinkSolution_Team_Board_1_Response from "./Team_Board/Team_Board_1_Response";
import ThinkSolution_Team_Board_2 from "./Team_Board/Team_Board_2";
import ThinkSolution_Team_Board_3 from "./Team_Board/Team_Board_Shared_goal";
import ThinkSolution_Multi_Slider_Response from "./ThinkSolutions/Multi_Slider";
import ThinkSolution_Text_Box from "./Team_Board/Text_Box";
import ThinkSolution_Text_Box2 from "./Team_Board/Text_Box2";
import ThinkSolution_Text_Box3 from "./Team_Board/Text_Box3";
import ThinkSolution_Text_Box4 from "./Team_Board/Text_Box4";
import ValueThinking_SP_Start_N_Stop from "./ValueThinking_SP/Engineer_Start_N_Stop";
import ValueThinking_SP_Bar_Chart from "./ValueThinking_SP/Engineer_Bar_Chart";
import ValueThinking_Team_Board_1 from "./ValueThinking_SP/Team_Board";
import ValueThinking_Team_Board_Display from "./ValueThinking_SP/TeamBoard_Display";
import Post_Session from "./Post_Session";
import CounsellingApp from "./counsellingApp";
import CounsellingOTP_analytics from "./CounsellingOTP_analytics";
import Live_Post_Session from "./Live_Post_Session";
import OTP from "./OTP";

export const LiveSessions = {
  ThinkSolution_Engineer_Start_N_Stop,
  ThinkSolution_Engineer_Bar_Chart,
  Poll_Dipstick,
  Dynamic_Custom,
  Real_World_Insights,
  Case_Response,
  Slider,
  ThinkSolution_Engineer_Trust_O_Meter,
  ThinkSolution_Role_Start_N_Stop,
  ThinkSolution_Entrepreneur_Start_N_Stop,
  ThinkSolution_Engineer_Pie_Chart,
  ThinkSolution_Idea_Board,
  ThinkSolution_Slider_Response,
  ThinkSolution_Idea_Board_Tree,
  ThinkSolution_Idea_Board_poll1,
  ThinkSolution_Idea_Board_poll2,
  ThinkSolution_Idea_Board_poll3,
  ThinkSolution_Idea_Board_poll4,
  ThinkSolution_SP_Engineer_Start_N_Stop,
  ThinkSolution_SP_Engineer_Bar_Chart,
  ThinkSolution_SP_Idea_Board,
  ThinkSolution_SP_Idea_Board_Step_1_Texbox,
  ThinkSolution_SP_Idea_Board_Step_3_Texbox,
  ThinkSolution_Idea_Bord_prir,
  ThinkSolution_Multi_Slider_Response,
  ThinkSolution_Team_Board_1,
  ThinkSolution_Team_Board_1_Response,
  ThinkSolution_Team_Board_2,
  ThinkSolution_Team_Board_3,
  ThinkSolution_Text_Box,
  ThinkSolution_Text_Box2,
  ThinkSolution_Text_Box3,
  ThinkSolution_Text_Box4,
  ValueThinking_SP_Start_N_Stop,
  ValueThinking_SP_Bar_Chart,
  ValueThinking_Team_Board_1,
  ValueThinking_Team_Board_Display,
  Post_Session,
  Live_Post_Session,
  OTP, Poll_Mcq, CounsellingApp, CounsellingOTP_analytics
};

export default ({
  width,
  height,
  currentUnit,
  engagementData,
  manageToolData,
  isFullScreen,
  data, language,
  typPC,
}) => {
  const { user_token } = useSelector((state) => state.auth);
  const { engagementStatus, currentEngagment, otpData, createdSession, response, uniqueStudentsName } = useSelector((state) => state.app);
  let { coachId } = createdSession || {}
  const sessionId = createdSession._id

  useEffect(() => {
    let otpDataCall = async () => {
      await getOTP({ user_token, sessionId, testType: 'pre', coachId })
    }
    if (coachId && sessionId && user_token && !Object.keys(otpData || {}).length > 0) { otpDataCall() }
  }, [coachId, sessionId, user_token])

  useEffect(() => {
    if (data && data.engagement) getEngagement(user_token, data.engagement);
  }, [data.engagement]);

  const responses = response[data.engagement]
  const uniqueStudentsName1 = uniqueStudentsName[data.engagement]
  const LiveSession = LiveSessions[data.sessionType];
console.log("engagementData", engagementData)
  return (
    <LiveSession
      language={language}
      width={width}
      height={height}
      typPC={typPC}
      engagementData={engagementData}
      manageToolData={manageToolData}
      data={data}
      isFullScreen={isFullScreen}
      engagementStatus={engagementStatus}
      sessionId={sessionId}
      responses={responses}
      currentEngagement={currentEngagment}
      uniqueStudentsName={uniqueStudentsName1}
      currentUnit={currentUnit}
    />
  );
};
